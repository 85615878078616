/* Existing Styles */
.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.gap-16 {
  gap: 4rem; /* 64px */
}

.gap-6 {
  gap: 1.5rem; /* 24px */
}

.gap-4 {
  gap: 1rem; /* 16px */
}

.gap-12 {
  gap: 3rem; /* 48px */
}

.items-center {
  align-items: center;
}

.w-24 {
  width: 6rem; /* 96px */
}

.h-24 {
  height: 6rem; /* 96px */
}

.max-w-full {
  max-width: 100%;
}

.max-h-full {
  max-height: 100%;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.object-cover {
  object-fit: cover;
}

.rounded-xl {
  border-radius: 0.75rem; /* 12px */
}

.rounded-md {
  border-radius: 0.375rem; /* 6px */
}

.cursor-pointer {
  cursor: pointer;
}

.text-violet-600 {
  color: #3aed73;
}

.text-violet-800 {
  color: rgb(82, 196, 82);
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.text-gray-700 {
  color: #4a5568;
}

.bg-gray-200 {
  background-color: #e2e8f0;
}

.bg-violet-800 {
  background-color: #21b65de4;
}

.text-white {
  color: #ffffff;
}

.py-2 {
  padding-top: 0.5rem; /* 8px */
  padding-bottom: 0.5rem; /* 8px */
}

.px-4 {
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
}

.px-5 {
  padding-left: 1.25rem; /* 20px */
  padding-right: 1.25rem; /* 20px */
}

.py-3 {
  padding-top: 0.75rem; /* 12px */
  padding-bottom: 0.75rem; /* 12px */
}

.px-16 {
  padding-left: 4rem; /* 64px */
  padding-right: 4rem; /* 64px */
}

.py-4 {
  padding-top: 1rem; /* 16px */
  padding-bottom: 1rem; /* 16px */
}

.px-6 {
  padding-left: 1.5rem; /* 24px */
  padding-right: 1.5rem; /* 24px */
}

.rounded-lg {
  border-radius: 0.5rem; /* 8px */
}

.h-full {
  height: 100%;
}

.img-container {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.thumbnail-img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 0.375rem;
  cursor: pointer;
}

/* Media Query for Large Screens */
@media (min-width: 1024px) {
  .lg\\:flex-row {
    flex-direction: row;
  }

  .lg\\:w-2-4 {
    width: 50%;
  }

  .lg\\:items-center {
    align-items: center;
  }

  .lg\\:gap-16 {
    gap: 4rem; /* 64px */
  }

  .lg\\:gap-6 {
    gap: 1.5rem; /* 24px */
  }

  .lg\\:gap-4 {
    gap: 1rem; /* 16px */
  }

  .lg\\:gap-12 {
    gap: 3rem; /* 48px */
  }

  .lg\\:img-container {
    gap: 1.5rem;
  }

  .lg\\:thumbnail-img {
    width: 4rem;
    height: 4rem;
  }
}

/* Media Query for Small Screens */
@media (max-width: 900px) {
  .btn-small {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
  .img-large {
    width: 90%;
    height: 60%;
  }
}
