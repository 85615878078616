.confirm-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background: #ffffff;

  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.confirm-header {
  text-align: center;
  margin-bottom: 20px;
}

.confirm-header h1 {
  color: rgba(111, 135, 81, 1);
  margin: 0;
  font-size: 36px;
  font-weight: 600;
}

.confirm-header h2 {
  color: #333;
  margin: 4px;
  font-size: 36px;
  font-weight: 600;
}

.confirm-main {
  padding: 20px;
}

.confirm-main p {
  margin: 10px 0;
  font-weight: 400;
  font-size: 16px;
}

.confirm-order-details {
  margin: 20px 0;

  border: 1px solid rgba(205, 205, 205, 1);
  border-radius: 24px;
  padding: 10px 0;
}

.confirm-order-item {
  display: flex;

  align-items: center;
  margin-bottom: 20px;
}

.confirm-order-item img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 5px;
}

.confirm-order-item div {
  flex: 1;
}

.confirm-order-item h3 {
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-family: Roboto;
}

.confirm-order-item p {
  margin: 5px 0;
  color: rgba(107, 107, 107, 1);
  font-weight: 400;
}

.support {
  text-align: center;
  margin: 20px 0;
  border-top: 1px solid rgba(216, 216, 216, 1);
  border-bottom: 1px solid rgba(216, 216, 216, 1);
  padding: 15px;
}

.support h3 {
  margin: 10px 0;
  font-size: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  line-height: 28px;
}

.support p {
  margin: 10px 0;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 13px;
}

.support button {
  color: rgba(111, 135, 81, 1);
  border: 1px solid rgba(111, 135, 81, 1);
  padding: 10px 20px;
  margin: 10px;
  border-radius: 24px;
  cursor: pointer;

  background-color: white;
  width: 265px;
  font-weight: 500;
}

.contact-footer {
  margin-top: 20px;
}

.contact-footer p {
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .order-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .order-item img {
    margin-bottom: 10px;
  }
}
