.product-grid {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
@media screen and (max-width: 1101px) {
  .product-grid {
    display: flex;
    justify-content: center;
  }
}
