.title-name {
  font-size: 30px;
  color: rgb(111, 135, 81);
  font-weight: 800;
  line-height: 1.2rem;
  height: 50px;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
.title {
  font-size: 30px;
  font-weight: 800;
}
.container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.accordion {
  width: 600px;
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  overflow: hidden;
}

.accordion-item {
  margin: 12px 0; /* Add margin to separate each item */
  background-color: #f9f9f9; /* Add background color */
  /* border: 1px solid #e0e0e0; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.accordion-header {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 8px; /* Round the corners of the header */
}

.accordion-header.active {
  background-color: #f1f1f1;
}

.title {
  flex: 1;
  margin: 0;
  font-size: 16px;
  color: #333333;
}

.icon {
  font-size: 23px;
  color: #333333;
}

.accordion-content {
  padding: 20px;
  font-weight: 600;
}

@media (max-width: 570px) {
  .title-name {
    font-size: 25px;
  }
}
