.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Roboto";
}
.error {
  color: red;
}

.back-button {
  align-self: flex-start;
  margin-bottom: 20px;
}

.back-button button {
  background: none;
  border: none;
  color: rgba(111, 135, 81, 1);
  font-size: 20px;
  cursor: pointer;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-summary,
.shipping-details {
  width: 48%;
}

.order-summary h2,
.shipping-details h2 {
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 6px;
  font-family: "Roboto";
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.item img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quantity-remove {
  display: flex;
  align-items: center;
  /* margin-top: 5px; */
}

.quantity-remove span {
  margin-right: 10px;
  margin-left: 10px;
}

.remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  background: rgba(237, 238, 240, 1);
  border-radius: 1px;
  color: rgba(88, 88, 88, 1);
  cursor: pointer;
}

.gift-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.gift-card input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}

.apply-btn {
  border: 1.4px solid rgba(111, 135, 81, 1);
  padding: 2px 18px;
  background-color: white;
  color: rgba(111, 135, 81, 1);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-family: "Roboto";
}
.remove-coupon-btn {
  border: 1.4px solid red;
  padding: 2px 18px;
  background-color: white;
  color: red;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-family: "Roboto";
}

.summary-details {
  padding-top: 20px;
}
.summary-item span {
  font-size: 15px;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  line-height: 30px;
}
.summary-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(60, 66, 87, 0.12);
}

.total {
  display: flex;
  justify-content: space-between;

  font-family: "Roboto";
  margin-top: 20px;
}

form {
  width: 100%;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.mb {
  margin-bottom: 19px;
}
.shipping .form-row .form-group label {
  color: black;
}
.details .form-row .form-group label {
  color: black;
}
.form-group {
  flex: 1;
  margin-bottom: 20px;
}
#giftGroup {
  padding: 2 px;
}
.details {
  border: 1px solid;
  border-color: rgb(220, 220, 220, 1);
  border-radius: 4px;
  padding: 12px;
  background-color: rgba(60, 66, 87, 0.02);
}
.form-group:not(:last-child) {
  margin-right: 10px;
}

.form-group label {
  display: block;
  font-size: 15px;
  font-family: "Roboto";
  list-style: 22px;
  font-weight: 500;
  color: rgba(88, 88, 88, 1);
  letter-spacing: 0.5%;
  margin-bottom: 5px;
  font-family: "Roboto";
}
.form-group input {
  border: 1px solid rgba(60, 66, 87, 0.12);
  border-radius: 4px;
}

.phone-number {
  display: flex;
  align-items: center;
}

.phone-number select {
  border: 1px solid rgba(60, 66, 87, 0.12);
  border-radius: 4px;
  margin-right: 10px;
  width: 75px;
}

input,
select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.btn-pay {
  /* padding: 10px 20px; */
  width: 136px;

  background-color: rgba(111, 135, 81, 1);
  color: white;
  height: 46px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  display: block;
  font-family: "Roboto";
}

.btn-pay:hover {
  background-color: darkgreen;
}
.progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.step {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #999;
  padding: 0 10px;
  font-family: "Roboto";
}

.step.completed {
  color: rgba(111, 135, 81, 1);
  font-weight: bold;
  font-family: "Roboto";
}

.divider {
  width: 40px;
  height: 2px;
  background-color: #999;
}

.divider.completed {
  background-color: rgba(111, 135, 81, 1);
}

/* Responsive Design */
@media (max-width: 960px) {
  .content {
    flex-direction: column;
  }

  .order-summary,
  .shipping-details {
    width: 100%;
  }

  .form-row {
    flex-direction: column;
  }

  .form-group {
    margin-right: 0;
  }
}
