.banner-images-top {
  width: 100%;
  height: 100%;
  transition: left 1s ease-in-out;
}
.left-portion {
  width: 50%;
}
.right-portion {
  width: 50%;
  height: 490px;
  position: relative;
}

.content-bottom {
  font-size: 20px;
  font-weight: 800;
}

.bottom-container {
  display: flex;
  background-color: rgb(111, 135, 81);
}
.box-bottom {
  margin-top: 100px;
  margin-left: 12px;
}
.title-text-bottom {
  position: relative;
  letter-spacing: 3px;
  z-index: 101;
  color: #fff;
  font-size: 20px;
  font-weight: 300;

  margin-bottom: 40px;
  padding-bottom: 15px;
}
.title-bottom {
  text-transform: "uppercase";
  letter-spacing: "2px";
  display: flex;
  justify-content: center;
  font-size: 38px;
  font-weight: 800;
  color: #fff;

  margin-top: 30px;
  margin-bottom: 30px;
}
.component-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 360px;
  width: 30%;
  justify-content: center;
  gap: 60px;
  margin-left: 20px;
  background-color: #fff;
}
.subtitle-bottom {
  color: rgb(125, 121, 121);
  font-size: 32px;
  font-weight: 500;
}
.fluid-section-bottom {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
  z-index: 100;
  top: 0%;

  background: rgba(25, 25, 25, 0.95);
}
@media (max-width: 1200px) {
  .banner-images-top {
    min-height: 80vh;
  }
}

@media (max-width: 992px) {
  .banner-images-top {
    min-height: 70vh;
  }
}

@media (max-width: 768px) {
  .banner-images-top {
    min-height: 100vh;
  }
}

@media (max-width: 576px) {
  .banner-images-top {
    min-height: 100vh;
  }
}

@media (max-width: 400px) {
  .banner-images-top {
    min-height: 40vh;
  }
}
@media (max-width: 984px) {
  .bottom-container {
    flex-direction: column;
  }
  .left-portion {
    width: 100%;
  }
  .right-portion {
    width: 100%;
    height: 490px;
  }
}
@media (max-width: 630px) {
  .title-text-bottom {
    margin-bottom: 0px;
  }
  .icon-bottom {
    width: 80px;
  }
  .title-bottom {
    font-size: 26px;
  }
  .subtitle-bottom {
    font-size: 20px;
  }
  .content-bottom {
    font-size: 14px;
  }
  .component-bottom {
    margin-left: 2px;
  }
}

.points-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 20px;
  margin: 2px auto;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.points-box {
  border-right: 1px dashed gray;
  width: 20%;
}

.points-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

.points-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  border: 3px solid black;
  border-radius: 50%;
  padding: 10px;
}

.points-description {
  font-size: 2rem;
  color: #8d8d8d;
}

/* Responsive Design - Media Queries */

/* Tablets - Medium screens (768px to 1024px) */
@media screen and (max-width: 1024px) {
  .points-box {
    width: 50%; /* Two boxes per row */
    border-right: none; /* Remove borders between columns */
    border-bottom: 1px dashed gray; /* Add border between rows */
  }

  .points-description {
    font-size: 1.8rem; /* Slightly smaller text */
  }
}

/* Mobile - Small screens (below 768px) */
@media screen and (max-width: 768px) {
  .points-box {
    width: 100%; /* Full width on small screens */
    border-bottom: 1px dashed gray;
    border-right: none;
  }

  .points-description {
    font-size: 1.5rem; /* Adjust font size for readability on small screens */
    text-align: center;
  }

  .points-icon {
    padding: 8px;
  }
}

/* Extra small screens (below 480px) */
@media screen and (max-width: 480px) {
  .points-container {
    padding: 10px;
  }

  .points-description {
    font-size: 1.2rem; /* Smaller text for extra small screens */
  }

  .points-icon {
    padding: 5px;
  }
}

.btn-container {
  margin: 40px;
  display: flex;
  gap: 12px;
  justify-content: center;
}

.res-btn {
  border: 3.3px solid black;
  width: 16%;
  height: 49px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.res-btn-text {
  font-size: 1.6rem;
  font-weight: bold;
  margin: auto;
}

/* Responsive Design */

/* Tablets - Medium screens (768px to 1024px) */
@media screen and (max-width: 1024px) {
  .res-btn {
    width: 25%; /* Adjust the button width */
    height: 45px; /* Adjust height */
  }

  .res-btn-text {
    font-size: 1.4rem; /* Slightly smaller font size */
  }
}

/* Mobile - Small screens (below 768px) */
@media screen and (max-width: 768px) {
  .btn-container {
    /* Stack buttons vertically */
    gap: 20px; /* Increase gap between buttons */
  }

  .res-btn {
    width: 60%; /* Full-width buttons */
    height: 45px;
  }

  .res-btn-text {
    font-size: 1.3rem; /* Adjust text size */
  }
}

/* Extra small screens (below 480px) */
@media screen and (max-width: 480px) {
  .res-btn {
    width: 70%; /* Full-width for extra small screens */
    height: 40px; /* Smaller height */
  }

  .res-btn-text {
    font-size: 1.1rem; /* Smaller font size */
  }

  .btn-container {
    margin: 20px; /* Smaller margins */
  }
}

/* Base styling */
.rewards-title h1 {
  font-size: 3rem;
  color: rgba(111, 135, 81, 1);
  font-weight: 800;
  text-align: center;
  margin: 0;
  padding: 0 20px;
}

.rewards-description h4 {
  color: #8d8d8d;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 10px;
}

.rewards-description h4 span {
  font-weight: bolder;
  color: black;
}

/* Tablet (768px and below) */
@media (max-width: 768px) {
  .rewards-title h1 {
    font-size: 2.5rem;
  }

  .rewards-description h4 {
    font-size: 1.2rem;
  }
}

/* Mobile (480px and below) */
@media (max-width: 480px) {
  .rewards-title h1 {
    font-size: 2rem;
  }

  .rewards-description h4 {
    font-size: 1rem;
  }
}
