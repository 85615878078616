* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.navbarContainer {
  width: 100%;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  border-radius: 10px;
  position: relative;
  z-index: 1000;
}

.logo-img {
  width: 75%;
}

.nav-menu {
  display: flex;
}

.logo {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: black;
  padding-left: 15px;
  font-weight: bold;
}

a.ListCoursesTopbar {
  margin: 0 3rem;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: black;
  transition: color 0.2s ease, background-color 0.2s ease,
    border-color 0.2s ease, box-shadow 0.2s ease, transform 0.2s ease;
}

ul li a.ListCoursesTopbar:hover {
  color: green;
  padding-bottom: 3px;
}

.hamburger {
  display: none;
  margin-right: 7vw;
}

.mobile-menu {
  display: none;
}

.icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.mobile-nav {
  display: none;
}

.activeClass {
  color: #ffff;
  padding-bottom: 3px;
}

.btn-contact {
  background-color: rgba(111, 135, 81, 1);
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 700;
  text-align: 15px;
  border-radius: 30px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
  margin-left: 10px;
}

.btn-cart {
  background-color: rgba(111, 135, 81, 1);
  font-size: 13px;
  padding: 8px 15px;
  font-weight: 700;
  text-align: 15px;
  border-radius: 30px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
  margin-left: 4px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 250px;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 20px;
  outline: none;
}

.search-input:focus {
  border-color: rgba(111, 135, 81, 1);
}

.search-results {
  position: absolute;
  width: 50%;
  top: 100%;
  left: 35%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1001;
  max-height: 200px;
  overflow-y: auto;
}

.search-results ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.search-results li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.search-results li a {
  text-decoration: none;
  color: black;
}

.search-results li a:hover {
  background: #f0f0f0;
}

/* MegaMenu Overlay */
.mega-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent backdrop */
  z-index: 998; /* Below MegaMenu but above other content */
}

/* MegaMenu Styles */
.mega-menu {
  position: absolute;
  top: 110px;
  left: 50%;
  transform: translateX(-50%); /* Center the MegaMenu */
  width: 80%; /* Reduced width on desktop */
  max-width: 800px; /* Maximum width for larger screens */
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: none;
  border-radius: 8px; /* Optional: Add rounded corners */
}

.mega-menu.active {
  display: block;
}

/* Close Button for MegaMenu (Visible on Mobile) */
.mega-menu-close-btn {
  display: none; /* Hidden on desktop */
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
  color: #000;
}

@media screen and (max-width: 1433px) {
  .navbarContainer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .mobile-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    top: -100vh;
    left: 0;
    position: absolute;
    justify-content: space-between;
    background-color: #3d3d3d;
    transition: 0.3s;
    color: #ffff;
  }

  div.mobile-menu.active {
    background-color: #3d3d3d;
    opacity: 85%;
    z-index: 45;
    top: 110px;
  }

  .mobile-menu li {
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: "Lato", sans-serif;
    color: #ffff;
    text-decoration: none;
  }

  .mobile-menu li:hover {
    color: #ffff;
    padding-bottom: 12px;
  }

  .active {
    top: 110px;
  }

  .mobile-nav {
    display: block;
    margin: 1rem 0;
    font-size: 20px;
    font-family: "Lato", sans-serif;
    color: #ffff;
    text-shadow: 0px 3px 6px #00000029;
  }

  .nav-menu {
    display: none;
  }

  .hamburger {
    display: block;
  }

  .mobile-menu-bottom {
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .nav-icons {
    display: none;
  }

  .mobile-menu button {
    margin: 1rem;
    width: 90%;
  }

  .logo {
    padding-left: 0;
  }

  .btn-contact {
    display: none;
  }
}

@media screen and (max-width: 1092px) {
  .mega-menu {
    position: fixed;
    top: 110px;
    left: 0;
    right: 0;
    transform: none; /* Remove centering transform on mobile */
    width: 100%; /* Full width on mobile */
    max-height: calc(100vh - 110px);
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 999;
    display: none;
    border-radius: 0; /* Remove rounded corners on mobile */
  }

  .mega-menu.active {
    display: block;
  }

  /* Show close button on mobile */
  .mega-menu-close-btn {
    display: block;
  }

  .mega-menu .ant-row {
    flex-direction: column;
  }

  .mega-menu .ant-col {
    width: 100%;
    padding: 0 10px;
  }

  .mega-menu .ant-menu {
    width: 100% !important;
    border-right: none !important;
  }

  .mega-menu .ant-menu-item-group-title {
    font-size: 16px !important;
    padding: 10px 16px !important;
  }

  .mega-menu .ant-menu-item {
    font-size: 14px !important;
    padding: 5px 16px !important;
  }

  .search-container {
    float: right;
    margin-left: 10px;
  }

  .search-input {
    width: 100%;
    font-size: 14px;
  }

  .search-results {
    left: 8%;
    width: 90%;
  }
}

@media screen and (max-width: 414px) {
  .navbarContainer {
    box-shadow: none;
  }

  .search-container {
    margin-left: 5px;
  }

  .search-input {
    font-size: 12px;
  }
}

@media screen and (max-width: 1340px) {
  .search-container {
    position: absolute;
    left: 150%;
  }
}

@media screen and (max-width: 1065px) {
  .search-container {
    position: absolute;
    left: 50%;
  }
}

@media screen and (max-width: 927px) {
  .search-container {
    position: absolute;
    left: 0%;
  }
}

@media screen and (max-width: 862px) {
  .search-container {
    position: absolute;
    left: -100%;
  }
}

@media screen and (max-width: 730px) {
  .search-container {
    position: absolute;
    left: -60%;
    width: 200px;
  }

  .logo-img {
    width: 60%;
  }
}

@media screen and (max-width: 550px) {
  .search-container {
    width: 130px;
    left: -40%;
  }
}