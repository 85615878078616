.form-container {
  display: flex;
  flex-direction: column;
  max-width: 40%;
  background: #c6dcba;
  margin: auto;
  margin-top: 80px;
  height: 100vh;
  border-radius: 15px;
  margin-bottom: 80px;
}
.form-input input {
  border: 1px solid black;
}
.form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}
.form-text {
  font-weight: 500;
  font-size: 3rem;
}
.form-underline {
  width: 80px;
  height: 6px;
  border-radius: 9px;
  background-color: black;
  margin-top: 8px;
}
.form-inputs {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.form-input {
  display: flex;
  align-items: center;
  margin: auto;
  width: 480px;
  height: 80px;
  background: #eaeaea;
  border-radius: 6px;
}
.form-icons {
  margin: 0px 30px;
}
.form-input input {
  height: 50px;
  width: 400px;
  background: transparent;
  border: none;
  outline: none;
  color: black;
  font-size: 19px;
}
.notes {
  height: 120px;
}
.form-button {
  display: flex;
  justify-content: center;
  margin: 30px auto;
}
.form-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 59px;
  color: #fff;
  background: #6ac610;
  font-weight: 700;
  font-size: 19px;
  border-radius: 50px;
}

@media (max-width: 1200px) {
  .form-input {
    max-width: 80%;
    width: 450px;
    height: 60px;
  }
  .form-input input {
    width: 74%;

    margin-right: 30px;
  }

  .form-container {
    height: 100vh;
  }
  .form-text {
    font-size: 2.5rem;
  }
}
@media (max-width: 610px) {
  .form-icons {
    margin: 0 18px;
  }
  .form-input {
    height: 50px;
  }
  .form-container {
    height: 70vh;
  }
  .form-input input::placeholder {
    font-size: 13px;
  }
  .form-input input {
    font-size: 13px;
  }
  .form-text {
    font-size: 1.9rem;
  }
  .form-underline {
    width: 30px;
    height: 3px;
  }
  .form-button button {
    width: 100px;
    height: 49px;
  }
}
@media (max-width: 400px) {
  .form-input {
    width: 150px;
    height: 30px;
  }
  .form-text {
    font-size: 1.5rem;
  }
}
