.whatsapp-icon-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 55px; /* Set a fixed width */
  height: 55px; /* Set a fixed height */
  background-color: #25d366;
  border-radius: 50%; /* Make it a perfect circle */
  display: flex; /* Enable flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 1000;
}

.whatsapp-icon {
  color: white;
  font-size: 30px;
  /* No need for margin-top or align-items here */
}

